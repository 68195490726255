:root {
  --main-bg-color: rgb(15, 15, 15);
  --main-text-color: rgb(232, 226, 218);
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.unselectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

body {
  font-family: "HergonGrotesk";
  font-weight: normal;
  font-size: 1vh;
}

.Header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 40vw;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  padding: 3vh 0 0;
}

.Logo {
  display: block;
  font-family: "Ode";
  font-size: 3rem;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .Header {
    display: none;
  }

  .Logo {
    font-size: 6vw;
  }
}