.contact-btn-ctn {
    /* margin: 1vh; */
    color: rgba(100, 100, 100, 255);
    background-color: rgba(35,35,35,255);
    border-radius: 0.5vh;
    padding: 1.5vh 1vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
    gap: 2vh;
    width: 100%;
}

.contact-btn-ctn:hover {
    cursor: pointer;
}

.contact-btn-text {
    font-size: 2vh !important; 
    padding: 0;
    margin: 0;
}

.glyphicon {
    font-size: 2vw;
}

@media only screen and (max-width: 600px) {
    .glyphicon {
        font-size: 4vh;
    }

    .contact-btn-ctn {
        width: 30vh;
        padding: 1.5vh 2vh;
    }

    .contact-btn-text {
        font-size: 1.5vh !important;
        padding: 0;
        margin: 0;
    }
}