.card {
  display: flex;
  position: relative;
  flex: 0 1 23%;
  flex-direction: column;
  background-color: #303030;
  border-radius: 0.5vh;
  border: none;
  /* outline-width: 0.25rem; */
  outline-style: solid;
  outline-offset: -2px;
  /* cursor: pointer; */
  height: 20vh;
  box-shadow: 0px 0.4rem 0.1rem 0.1rem rgba(10, 10, 10, 0.2);
  -webkit-box-shadow: 0px 0.4rem 0.1rem 0.1rem rgba(10, 10, 10, 0.2);
  -moz-box-shadow: 0px 0.4rem 0.1rem 0.1rem rgba(10, 10, 10, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  justify-content: end;
}

.card:hover {
  cursor: pointer;
}

.card:active {
}

.card-thumbnail-ctn {
  display: flex;
  max-width: 7vw;
  max-height: auto;
}

.card-title {
  margin: 0;
  padding: 1vh;
  width: 100%;
  font-size: 1.25vh;
  font-weight: 600;
  text-align: left;
  display: block;
  background-color: rgba(0.1, 0.1, 0.1, 0.5);
  backdrop-filter: blur(1rem);
  box-sizing: border-box;
  border: none;
}

.card img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  /* border-radius: 0.3rem; */
  aspect-ratio: 1/1;
}

@media only screen and (max-width: 600px) {
  .card {
    flex: 1 0 35%;
    height: 17vh;
    max-width: 30vw;
    overflow: auto;

  }

  .card-title {
    font-size: 0.75rem;
    padding: 0.5rem 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}