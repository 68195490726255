:root {
    --caret-padding: 10vw;
    --transparent-stop: 30%;
    --bg-stop: 90%;
    --caret-bg-color: rgba(25, 25, 25, 1);
}

.blog-list-ctn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vw;
    padding: 0;
    width: 100%;
    /* height: 20vh; */
    flex-wrap: nowrap;
}

.blog {
    margin: 0 0 3vh;
}

.contact-ctn {
    
}

.caret {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    top: 0;
    opacity: 0;
    font-size: 2rem;
}

@media only screen and (max-width: 600px) {
    .blog-list-ctn {
        padding: 0 0 1vh;
        gap: 4vw;
        justify-content: flex-start;
        overflow-x: scroll;
        scroll-behavior: smooth;
        position: relative;
    }

    .blog-list-ctn::-webkit-scrollbar {
        height: 0.5vh;
        box-sizing: content-box;
        /* border-radius: 5%; */
    }
    
    .blog-list-ctn::-webkit-scrollbar:disabled {
        display: none;
    }
    
    .blog-list-ctn::-webkit-scrollbar-track {
        margin: 0 0.5vw 0;
        background-color: rgba(0.2, 0.2, 0.2, 0.2);
        /* border-radius: 5%; */
    }

    .blog {
        position: relative;
        margin: 0 0 5vh;
    }

    .left-caret {
        left: 0;
        background-image: linear-gradient(to left, transparent var(--transparent-stop), var(--caret-bg-color) var(--bg-stop));
        padding: 0 var(--caret-padding) 0 0;
    }
    
    .right-caret {
        right: 0;
        background-image: linear-gradient(to right, transparent var(--transparent-stop), var(--caret-bg-color) var(--bg-stop));
        padding: 0 0 0 var(--caret-padding);
    }
}