.Navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.listContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.listContainer .Logo {
  font-size: 1.5vh;
}

.Navbar li {
  font-family: "HergonGrotesk";
  text-transform: uppercase;
  font-weight: 800;
  display: inline-block;
  margin-left: 1.5vw;
  padding: 0.5vh 0.4vw 0.3vh;
  border-radius: 0.2vh;
  font-size: 1.2vh;
  /* cursor: pointer; */
  vertical-align: baseline;
  transition: 0.3s;
  /* text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 0.5vh;
  text-underline-position: under; */
}

.Navbar li:not(.navbar-selected):hover {
  color: hsl(37, 60%, 75%) !important;
}

@media only screen and (max-width: 600px) {
  
}