.btn {
    outline: 0.15rem solid rgba(89,54,4,0);
    /* background-image: radial-gradient(circle, rgba(172,113,19,1) 0%, rgba(230,157,41,1) 60%); */
    padding: 1vh 1vw;
    width: 100%;
    display: flex;
    /* font-family: "JetBrains Mono"; */
    font-size: 0.8vw;
    /* font-weight: 600; */
    /* cursor: pointer; */
    border-radius: 0.25rem;
    flex: 1 1 100%;
    justify-content: center;
    border: none;
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
    .btn {
        color: rgba(89,54,4,255) !important;
        /* outline-width: 0.15rem !important; */
        /* outline-color: rgba(89,54,4,255) !important; */
    }
}