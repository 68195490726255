:root {
  /* background: linear-gradient(135deg, #191919 25%, #181818 25%, #181818 50%, #191919 50%, #191919 75%, #181818 75%, #181818 100%); */
  /* background-size: 113.14px 113.14px; */
}

.section {
  padding: 0;
}

.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  box-shadow: 0 0 4vw 3vw rgba(20,20,20,1) inset;
  pointer-events: none;
}

.about-content {
  display: flex;
  align-items: flex-start;
  flex: 2 1 100%;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
}

.about-canvas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

.about-canvas canvas {
  width: 100%;
  height: 100%;
}

.about-canvas video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  filter: brightness(0.5);
}

.about-info {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  /* margin-left: 4vw; */
  z-index: 99;
  /* backdrop-filter: blur(0.25rem); */
  /* padding: 8vw; */
  margin: 0 0 6vw 6vw;
  padding-right: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  transform-origin: center left;
  /* border-left: 0.25rem solid rgba(230,157,41,0.5); */
}

.about-btn-ctn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
  box-sizing: border-box;
}

.about-btn-ctn .btn {
  flex: 0 1 25%;
}

.about-img {
  display: flex;
  margin-left: 3vw;
  max-width: 15vw;
  max-height: auto;
}

.about-img img {
  /* outline: 0.1vw solid rgba(230,157,41,1); */
  outline-offset: 0rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  border-radius: 100%;
}

.about-text {
  text-justify: inter-word;
  text-align-last: left;
  line-height: 2vh;
  hyphens: auto;
  text-align: justify;
  /* font-family: "HergonGrotesk"; */
  font-size: 1vw;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: 0 0 4vh;
  /* padding: 4vh; */
  /* background-color: rgba(0.1, 0.1, 0.1, 0.75); */
  /* backdrop-filter: blur(2vh); */
  /* border-radius: 0.25vh; */
  box-sizing: border-box;
  display: block;
}

.about-text ul {
  padding: 0;
  margin: 0;
}

.about-text li {
  list-style-type: none;
  list-style-position: inside;
  line-height: 2.5vh;
}

.about-text li::before {
  content: "//";
  font-weight: 800;
  font-size: 1vw;
  letter-spacing: -0.1vw;
  margin-right: 0.75vw;
}

.blog-section-ctn {
  width: 40vw;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  top: 0;
}

.contact-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 20vw;
  margin: 0 auto;
}

#contact p {
  text-align: center;
  font-size: 2vh;
}

h2, h1:not(.Logo) {
  margin-bottom: 2vh;
}

h2 {
  margin-bottom: 1.5vh;
}

#works h1, #featured h1 {
  margin-top: 12vh;
}

hr {
  display:inline-block;
}

.Logo.about {
  margin: 0 0 3vh 0;
  font-size: 3vw;
  line-height: 8vh;
  display: block;
  position: relative;
  /* background: radial-gradient(circle, hsl(40, 100%, 70%) 0%, hsl(40, 90%, 60%) 100%); */
  background-color: rgba(230,157,41,1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* 
.Logo.about::after {
  text-shadow: 0 0 4rem rgba(0.1, 0.1, 0.1, 0.75);
  color: transparent;
  content: "HUU QUANG NGUYEN";
  position: absolute;
  left: 0;
} */

@media only screen and (max-width: 600px) {
  .about-btn-ctn {
    padding: 0 2rem;
  }

  .about-btn-ctn .btn {
    font-size: 2vh;
    outline-width: 0.15vh !important;
    padding: 1vh 0;
    font-weight: 600 !important;
    outline-color: rgba(89,54,4,0.5) !important;
    flex: none;
  }

  .about-info {
    margin: 0;
    padding: 8vh 0;
    width: 100vw;
    border-radius: 0;
  }

  .about-text li::before {
    content: "//";
    font-weight: 800;
    font-size: 2vh;
    letter-spacing: -0.25vh;
    margin-right: 1vh;
  }

  .about-text {
    font-size: 2vh;
    padding: 4vh 4vh;
    line-height: 2.5vh;
    text-align: justify;
    display: block;
    border-radius: 0;
  }

  .Logo.about {
    font-size: 2.95vh;
    white-space: nowrap;
    line-height: 3vh;
    text-align: center;
  }

  .about-text ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .about-text li {
    line-height: 1.5rem;
  }

  .blog-section-ctn {
    width: 90vw;
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto 0;
    top: 0;
  }

  #works h1, #featured h1 {
    margin-top: 6vh;
    margin-bottom: 2vh;
    font-size: 4vh;
  }

  h2 {
    font-size: 5vw;
  }

  #contact p {
    text-align: center;
    font-size: 4vw;
  }

  .contact-ctn {
    gap: 1vw;
  }
}