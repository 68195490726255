.feature-list-ctn {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

@media only screen and (max-width: 600px) {
    .feature-list-ctn {
        gap: 4vh;
    }
  }