.preview {
    flex: 1 1;
    /* cursor: pointer; */
}

.feature-info {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    width: 100%;
    height: 24vh;
    box-sizing: border-box;
    border-style: solid;
    border-radius: 0.8vh;
    outline-style: solid;
    border: none;
    overflow: hidden;
    outline-style: solid;
}

.feature-info:hover {
    cursor: pointer;
}

.feature-info .content {
    width: 13vw;
    backdrop-filter: blur(2rem);
    display: flex;
    height: 100%;
    padding: 2vh 2vw;
    box-sizing: border-box;
    background-color: rgba(0.1, 0.1, 0.1, 0.5);
    flex-direction: column;
    justify-content: center;
}

h1.feature-title {
    margin: 0 !important;
    font-size: 1.5vw;
}

.feature-desc {
    font-size: 0.65vw;
    text-align: left;
    margin-bottom: 0;
    display: block;
}

@media only screen and (max-width: 600px) {
    .feature-desc {
        display: none;
    }

    .feature-info .content {
        width: 100%;
        backdrop-filter: none;
        display: flex;
        height: 100%;
        padding: 4vw;
        box-sizing: border-box;
        background-color: rgba(0.1, 0.1, 0.1, 0);
        flex-direction: column;
        justify-content: flex-end;
    }

    h1.feature-title {
        margin: 0 !important;
        font-size: 5vw !important;
        width: min-content;
        padding: 1vh 3vw;
        background-color: rgba(0.1, 0.1, 0.1, 0.5);
        border-radius: 0.5rem;
        backdrop-filter: blur(1rem);
    }
}