@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;400;800&display=swap');

@font-face {
  font-family: 'Cufel';
  src: url('CUFEL.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ode';
  src: url('OdeToIdle.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HergonGrotesk';
  src: url('HergonGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HergonGrotesk';
  src: url('HergonGrotesk-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HergonGrotesk';
  src: url('HergonGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HergonGrotesk';
  src: url('HergonGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HergonGrotesk';
  src: url('HergonGrotesk-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('AvantGardeDemi.woff2') format('woff2'),
      url('AvantGardeDemi.woff') format('woff'),
      url('AvantGardeDemi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('AvantGarde.woff2') format('woff2'),
      url('AvantGarde.woff') format('woff'),
      url('AvantGarde.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('AvantGardeBook.woff2') format('woff2'),
      url('AvantGardeBook.woff') format('woff'),
      url('AvantGardeBook.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

