/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(50,50,50,0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(50, 50, 50, 1);
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blog-panel-ctn {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.blog-panel {
  display: none;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: rgba(15, 15, 15, 0.75);
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2rem);
  box-sizing: content-box;
}

.blog-panel {
  font-size: 1.5vh;
}


.content-ctn {
  padding: 6vw;
  overflow: auto;
  background-color: rgba(20, 20, 20, 1);
  width: 45%;
  height: 100vh;
  flex: 1 1 100vh;
  z-index: 9999;
  top: 0;
  box-sizing: border-box;
  position: relative;
}

.blog-panel .bg {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
.blog-panel .content h2,
.blog-panel .content h1 {
  padding: 0;
  margin: 0 0 1.5rem 0 !important;
}

.blog-panel .content h1 {
  font-size: 3vh;
}

.blog-panel .content img {
  border-radius: 0.35vw;
  display: inline-block;
  margin: 0 auto;
}

.blog-panel .content span.fr-dib img {
  display: block;
}

.blog-panel .content span.fr-dii img {
  margin: 0 auto;
}

.blog-panel .btn {
  border: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  top: 4vh;
  right: 30vw;
  width: 2.5vh;
  height: 3.5vh;
}

@media only screen and (max-width: 600px) {
  .content-ctn {
    margin: 0;
    padding: 12vh 3vh 4vh 4vh;
    width: 100%;
    overflow-x: hidden;
  }

  .blog-panel {
    font-size: 2vh;
    text-align: justify;
    word-break: break-word;
    hyphens: auto;
  }
  
  .blog-panel .bg {
    display: none;
    position: relative;
    width: 0;
    height: 0;
  }

  .blog-panel .btn {
    right: 3.5vh;
    width: 5vh;
    height: 5vh;
    text-align: center;
  }

  .blog-panel .btn-txt {
    font-size: 1rem;
  }

  .blog-panel iframe {
    max-width: 38vh;
    max-height: 24vh;
  }
}